import { useState } from "react";
import IconSvg from "../components/icon/svgIcon";
import { Item, Amount, Dimension } from "../helper/classes"

function SvgTestPage() {
  
    const Widht1 = 30
    const Widht2 = 50
    const Widht3 = 100

    const Items = [
        new Item("test", new Amount(1, "", "1"), undefined, undefined, undefined),
        new Item("test", new Amount(2, "", "2"), undefined, undefined, undefined),
        new Item("test", new Amount(3, "", "3"), undefined, undefined, undefined),
        new Item("test", new Amount(4, "", "4"), undefined, undefined, undefined),
        new Item("test", new Amount(5, "", "5"), undefined, undefined, undefined),
        new Item("test", new Amount(6, "", "6"), undefined, undefined, undefined),
        new Item("test", new Amount(7, "", "7"), undefined, undefined, undefined),
        new Item("test", new Amount(8, "", "8"), undefined, undefined, undefined),
        new Item("test", new Amount(9, "", "9"), undefined, undefined, undefined),
        new Item("test", new Amount(10, "", "10"), undefined, undefined, undefined),
        new Item("test", new Amount(0.5, "g", "1kg"), undefined, undefined, undefined),
        new Item("test", new Amount(1, "g", "1kg"), undefined, undefined, undefined),
        new Item("test", new Amount(2, "g", "1kg"), undefined, undefined, undefined),
        new Item("test", new Amount(3, "g", "1kg"), undefined, undefined, undefined),
        new Item("test", new Amount(4, "g", "1kg"), undefined, undefined, undefined),
        new Item("test", new Amount(5, "g", "1kg"), undefined, undefined, undefined),
        new Item("test", new Amount(1, "l", "1l"), undefined, undefined, undefined),
        new Item("test", new Amount(0.75, "l", "1l"), undefined, undefined, undefined),
        new Item("test", new Amount(0.5, "l", "1l"), undefined, undefined, undefined),
        new Item("test", new Amount(0.25, "l", "1l"), undefined, undefined, undefined),
        new Item("test", new Amount(0, "l", "1l"), undefined, undefined, undefined),
        new Item("test", new Amount(1, "", "1"), new Dimension(10, 10, 10), undefined, undefined),
        new Item("test", new Amount(1, "", "1"), new Dimension(10, 200, 300), undefined, undefined),
        new Item("test", new Amount(1, "", "1"), new Dimension(20, 220, 300), undefined, undefined),
        new Item("test", new Amount(1, "", "1"), new Dimension(50, 220, 300), undefined, undefined),
        new Item("test", new Amount(1, "", "1"), new Dimension(50, 50, 24), undefined, undefined),
        new Item("test", new Amount(1, "", "1"), new Dimension(50, 50, 10), undefined, undefined),
        new Item("test", new Amount(1, "", "1"), new Dimension(120, 120, 50), undefined, undefined),
        new Item("test", new Amount(1, "", "1"), new Dimension(1200, 1200, 500), undefined, undefined),
        new Item("test", new Amount(1, "", "1"), new Dimension(1500, 500, 900), undefined, undefined),
        new Item("test", new Amount(1, "", "1"), new Dimension(30, 30, 190), undefined, undefined),
        new Item("test", new Amount(1, "", "1"), new Dimension(300, 300, 1900), undefined, undefined),
        new Item("test", new Amount(1, "", "1"), new Dimension(2000, 1000, 900), undefined, undefined),
        new Item("test", new Amount(1, "", "1"), new Dimension(1000, 2000, 900), undefined, undefined),
    ]

    return (
        <div>
            <h1>svg test</h1>
            <div>
                {Items.map((item, x) =>
                    <IconSvg Item={item} key={x} Height={Widht1} Width={Widht1} Radius={Widht1 / 2} />
                )}
            </div>
            <div>
                {Items.map((item, x) =>
                    <IconSvg Item={item} key={x} Height={Widht2} Width={Widht2} Radius={Widht2 / 2} />
                )}
            </div>
            <div>
                {Items.map((item, x) =>
                    <IconSvg Item={item} key={x} Height={Widht3} Width={Widht3} Radius={Widht3 / 2} />
                )}
            </div>
            <hr />
            <div>
                {Items.map((item, x) =>
                    <IconSvg className="green" Item={item} key={x} Height={Widht1} Width={Widht1} Radius={Widht1 / 4} />
                )}
            </div>
            <div>
                {Items.map((item, x) =>
                    <IconSvg className="green" Item={item} key={x} Height={Widht2} Width={Widht2} Radius={Widht2 / 4} />
                )}
            </div>
            <div>
                {Items.map((item, x) =>
                    <IconSvg className="green" Item={item} key={x} Height={Widht3} Width={Widht3} Radius={Widht3 / 4} />
                )}
            </div>
            <hr />

            <div>
                {Items.map((item, x) =>
                    <IconSvg className="red" Item={item}  key={x} Height={Widht1} Width={Widht1} Radius={Widht1 / 10} />
                )}
            </div>
            <div>
                {Items.map((item, x) =>
                    <IconSvg className="red" Item={item} key={x} Height={Widht2} Width={Widht2} Radius={Widht2 / 10} />
                )}
            </div>
            <div>
                {Items.map((item, x) =>
                    <IconSvg className="red" Item={item} key={x} Height={Widht3} Width={Widht3} Radius={Widht3 / 10} />
                )}
            </div>
            <hr />


        </div>
    );
}

export default SvgTestPage;