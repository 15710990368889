/**
 * Input field
 * @param {{onChange:React.ChangeEventHandler}} props 
 * @returns Common Input
 */
function SingleInputField(props) {

    const handleChange = (event) => {
        props.onChange(event.target.value)
    }

    return (
        <div>
            <input type="text" onChange={handleChange} />
        </div>
    );
}

export default SingleInputField;