import { useEffect, useState } from "react"
import ItemCard from "./item.card"

/**
 * Generates a list of Items as one-liner
 * @param {{Items:Item[]}} props
 * @returns List of Items
 */
function ItemList(props) {
    const [Items, setItems] = useState([])

    const propItems = props?.Items

    useEffect(() => {
        setItems(propItems ?? [])
    }, [propItems])

    const f = () => {
        if (Items.length === 0) {
            return (
                <div></div>
            )
        }
        return (
            <div>
                {Items && Items.map(
                    (item) =>
                        <ItemCard Item={item} Small="true" key={item.uuid} GetUrl={props.GetUrl} />
                )}
            </div>
        )

    }

    return f();
}

export default ItemList;