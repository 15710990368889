class V3d {
    constructor(x, y, z) {
        this.X = x
        this.Y = y
        this.Z = z
    }

    /**
     * Vector Length
     * @returns lenth of vector
     */
    Length() {
        return Math.sqrt(this.X * this.X + this.Y * this.Y + this.Z * this.Z)
    }

    /**
     * Unifies Vector
     * @returns Unified Vector
     */
    Unitize() {
        const length = this.Length()
        return new V3d(this.X / length, this.Y / length, this.Z / length)
    }

    /**
     * Add two vectors
     * @param {V3d} pt2 Vector to add
     * @returns sum vector
     */
    Add(pt2) {
        return new V3d(this.X + pt2.X, this.Y + pt2.Y, this.Z + pt2.Z)
    }

    /**
     * Subtract two vectors
     * @param {V3d} pt2 Vector to subtract
     * @returns subtraction vector
     */
    Sub(pt2) {
        return new V3d(this.X - pt2.X, this.Y - pt2.Y, this.Z - pt2.Z)
    }

    /**
     * Calculate Dot Product of two vectors 
     * @param {V3d} pt2 other vector
     * @returns dot product
     */
    Dot(pt2) {
        return this.X * pt2.X + this.Y * pt2.Y + this.Z * pt2.Z
    }

    /**
     * Scales the current vector
     * @param {number} fac Scale factor
     */
    Scale(fac) {
        this.X = this.X * fac
        this.Y = this.Y * fac
        this.Z = this.Z * fac
    }

    /**
     * Calculates the cross Product of two vectors
     * @param {V3d} pt2 Other Vector
     * @returns cross product
     */
    Cross(pt2) {
        return new V3d(
            this.Y * pt2.Z - this.Z * pt2.Y,
            this.Z * pt2.X - this.X * pt2.Z,
            this.X * pt2.Y - this.Y * pt2.X,
        )
    }

    /**
     * Calculate distance to other point
     * @param {V3d} pt other point
     * @returns distance
     */
    DistTo(pt) {
        return this.Sub(pt).Length()
    }

    /**
     * Copy point
     * @returns copy of point
     */
    Copy() {
        return new V3d(this.X, this.Y, this.Z)
    }
}

/**
 * Line between two points
 */
class Line3d {
    /**
     * Creates Line between two points 
     * @param {V3d} p1 start point
     * @param {V3d} p2 end point
     */
    constructor(p1, p2) {
        this.S = p1
        this.E = p2
    }

    /**
     * Calculates the length of the line
     * @returns length
     */
    Length() {
        return this.S.DistTo(this.E)
    }
}

/**
 * Face with normal direction
 */
class Plane {
    /**
     * Creates a Plane
     * @param {V3d} p Plane point
     * @param {V3d} v Plane normal
     */
    constructor(p, v) {
        this.P = p
        this.V = v.Unitize()
    }

    /**
     * Returns Distance to ther Points
     * @param {V3d} pt Other Point
     * @returns distance
     */
    Dist(pt) {
        const s = this.P.Sub(pt)
        return this.V.Dot(s)
    }

    /**
     * Calculates intersection point
    * @param {Line3d} Line Intersection Line
     * @returns intersection point on plane
     */
    Intersect(Line) {
        const ds = this.Dist(Line.S)
        const de = this.Dist(Line.E)
        if (de * ds > 0) {
            return undefined
        }

        const dsttotal = Math.abs(ds - de)
        const Vec = Line.E.Sub(Line.S)
        Vec.Scale(Math.abs(ds) / dsttotal)
        return Line.S.Add(Vec)
    }
}

/**
 * Camera with projection plane
 */
class Camera {
    /**
     * 
     * @param {V3d} focus focus point
     * @param {V3d} cam camera position
     * @param {number[]} dim dimension of the screen
     */
    constructor(focus, cam, dim) {
        /** Camera focus point */
        this.Focus = focus
        /** Camera position */
        this.Cam = cam
        /** Camera view vector */
        this.Vec = focus.Sub(cam).Unitize()

        const planeCenterPoint = this.Cam.Add(this.Vec)
        /** Camera Plane */
        this.Plane = new Plane(planeCenterPoint, this.Vec)
        /** Horizontal Axis */
        this.Hor = new V3d(1, 0, 0)
        /** Vertical Axis */
        this.Ver = new V3d(0, 1, 0)
        /** Image Dimensions */
        this.Dim = dim
        /** Offset of rendered point in x */
        this.OffsX = dim[0] / 2
        /** Offset of rendered point in y */
        this.OffsY = dim[1] / 2

        this.SetPlane(1)
    }

    /**
     * Set Plane vector
     * @param {number} factor factor for sceen size
     */
    SetPlane(factor) {
        this.Hor = new V3d(0, 0, 1).Cross(this.Vec)
        this.Ver = this.Vec.Cross(this.Hor)
        this.Hor.Scale(factor)
        this.Ver.Scale(factor)
    }

    /**
     * Fit scale factor that icon fits into the image
     * @param {point[]} points Points to fit in the image
     */
    AutoFocus(points, borderOffset) {
        this.SetPlane(1)
        let maxx = 0
        let maxy = 0
        let minx = Number.MAX_SAFE_INTEGER
        let miny = Number.MAX_SAFE_INTEGER
        points.forEach(point => {
            const [x, y] = this.Project(point)
            maxx = Math.max(maxx, x)
            maxy = Math.max(maxy, y)
            minx = Math.min(minx, x)
            miny = Math.min(miny, y)
        });
        const [screenUsageX, screenUsageY] = [this.Dim[0] - 2*borderOffset, this.Dim[1] - 2*borderOffset]
        const [maxDimensionX, maxDimensionY] = [Math.abs(maxx - minx), Math.abs(maxy - miny)]
        const [dx, dy] = [maxDimensionX / screenUsageX, maxDimensionY / screenUsageY]
        const max = Math.max(dx, dy)
        this.SetPlane(1 / max)
    }

    /**
     * Calculate the projection of point on screen
     * @param {V3d} pt point to project on screen
     * @returns coordinate on screen
     */
    Project(pt) {
        const Pt = this.Plane.Intersect(new Line3d(this.Cam, pt)).Sub(this.Plane.P)
        if (Pt === undefined) {
            return undefined
        }
        const dot1 = Pt.Dot(this.Hor)
        const dot2 = Pt.Dot(this.Ver)
        return [0 - dot1 + this.OffsX, 0 - dot2 + this.OffsY]
    }

}

/**
 * Ortogonal watch
 */
class Mat3 {
    /**
     * Creates new Matrix
     * @param {V3d|undefined} v1 x-vector
     * @param {V3d|undefined} v2 y-vector
     * @param {V3d|undefined} v3 z-vector
     * @returns new matrix
     */
    constructor(v1, v2, v3) {
        if (v1 === undefined && v2 === undefined && v3 === undefined) {
            this.SetUnitMatrix()
            return
        }
        this.VX = v1.Unitize()
        this.VY = v2.Unitize()
        if (v3 === undefined) {
            this.VZ = this.VX.Cross(this.VY)
            return
        }
        this.VZ = v3.Unitize()
    }

    SetUnitMatrix() {
        this.VX = new V3d(1, 0, 0)
        this.VY = new V3d(0, 1, 0)
        this.VZ = new V3d(0, 0, 1)
    }

    /**
     * Matrix to Point multiplication
     * @param {V3d} p point to multiply
     * @returns point in matrix coordinate system
     */
    Mult(p) {
        return new V3d(
            p.X * this.VX.X + p.Y * this.VY.X + p.Z * this.VZ.X,
            p.X * this.VX.Y + p.Y * this.VY.Y + p.Z * this.VZ.Y,
            p.X * this.VX.Z + p.Y * this.VY.Z + p.Z * this.VZ.Z,
        )
    }

    /**
     * Calculate inverse matrix
     * @returns inverse of matrix
     */
    Inv() {
        const unitmat = new Mat3()
        const invmat = new Mat3(this.VX.Copy(), this.VY.Copy(), this.VZ.Copy())

        let fac = 1.0

        if (invmat.VX.X !== 0) {
            if (invmat.VY.X !== 0) {

                fac = invmat.VY.X / invmat.VX.X
                unitmat.VY.Scale(1 / fac)
                unitmat.VY = unitmat.VY.Sub(unitmat.VX)
                
                invmat.VY.Scale(1 / fac)
                invmat.VY = invmat.VY.Sub(invmat.VX)
            }

            if (invmat.VZ.X !== 0) {
                fac = invmat.VZ.X / invmat.VX.X
                unitmat.VZ.Scale(1 / fac)
                unitmat.VZ = unitmat.VZ.Sub(unitmat.VX)
                invmat.VZ.Scale(1 / fac)
                invmat.VZ = invmat.VZ.Sub(invmat.VX)
            }

        }

        if (invmat.VY.Y !== 0) {
            if (invmat.VZ.Y !== 0) {
                fac = invmat.VZ.Y / invmat.VY.Y
                unitmat.VZ.Scale(1 / fac)
                unitmat.VZ = unitmat.VZ.Sub(unitmat.VY)
                invmat.VZ.Scale(1 / fac)
                invmat.VZ = invmat.VZ.Sub(invmat.VY)
            }
        }

        if (invmat.VY.Z !== 0) {
            fac = invmat.VY.Z / invmat.VZ.Z
            unitmat.VY.Scale(1 / fac)            
            unitmat.VY = unitmat.VY.Sub(unitmat.VZ)

            invmat.VY.Scale(1 / fac)
            invmat.VY = invmat.VY.Sub(invmat.VZ)
        }


        if (invmat.VX.Z !== 0) {
            fac = invmat.VX.Z / invmat.VZ.Z
            unitmat.VX.Scale(1 / fac)
            unitmat.VX = unitmat.VX.Sub(unitmat.VZ)
            
            invmat.VX.Scale(1 / fac)
            invmat.VX = invmat.VX.Sub(invmat.VZ)
        }

        if (invmat.VX.Y !== 0) {

            fac = invmat.VX.Y / invmat.VY.Y
            unitmat.VX.Scale(1 / fac)
            unitmat.VX = unitmat.VX.Sub(unitmat.VY)
            
            invmat.VX.Scale(1 / fac)
            invmat.VX = invmat.VX.Sub(invmat.VY)
        }


        fac = invmat.VZ.Z
        unitmat.VZ.Scale(1 / fac)
        invmat.VZ.Scale(1 / fac)
        fac = invmat.VY.Y
        unitmat.VY.Scale(1 / fac)
        invmat.VY.Scale(1 / fac)
        fac = invmat.VX.X
        unitmat.VX.Scale(1 / fac)
        invmat.VX.Scale(1 / fac)

        return unitmat
    }
}

export { V3d, Line3d, Plane, Mat3, Camera }