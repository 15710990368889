const matchAmount = /(\d+[.,]?\d*) ?([µmnkM]?[lLg])?\b/

const matchDimension = /(\d+[.,]?\d*)(?:mm)? ?x ?(\d+[.,]?\d*)(?:mm)?(?: ?x ?(\d+[.,]?\d*)(?:mm)?)?\b/

const matchDate1 = /(\d{1,2})\.(\d{1,2})\.(\d{2,4})?/
const matchDate2 = /(\d{4})-(\d{1,2})-(\d{1,2})/

/**
 * Holds an Item
 */
class Item {
    /**
     *
     * @param {uuid} uid UUID
     * @param {string} name Name
     * @param {object} params Amount
     */
    constructor(uuid, name, params) {
        this.uuid = uuid
        this.params = params||{}
        this.params.name=name
    }
}

/**
 * Holds an Amount
 */
class Amount {
    /**
     * Representation eines Amounts
     * @param {number|undefined} value Betrag
     * @param {string|undefined} type Type
     * @param {string|undefined} rep Beschreibung
     */
    constructor(value, type, rep) {
        this.value = value ?? 1
        this.type = type ?? ""
        this.representation = rep ?? Amount.Rep(this.value, this.type)
    }

    /**
     * Changes the amount to an readable format
     * @param {number} v Amount Value
     * @param {string} a Amount Type
     * @returns Normalized Values
     */
    static GetNormedValueAndFactor(v, a) {
        let Value = v
        let Amount = a
        const r = /([kmnµ]?)([glLm])/
        if (r.test(a)) {
            const p = r.exec(a)
            if (p[1] === 'm') {
                Amount = p[2]
                Value /= 1e3
            }
            if (p[1] === 'µ') {
                Amount = p[2]
                Value /= 1e6
            }
            if (p[1] === 'k') {
                Amount = p[2]
                Value *= 1e3
            }
        }
        Value *= 1.0
        return [Value, Amount]
    }

    /**
     * Parses an text to be an
     * @param {string} text Text to be parsed
     * @returns Amount
     */
    static Parse(text) {
        if (matchAmount.test(text) === false) {
            return undefined
        }
        const textMatches = matchAmount.exec(text)
        const va = Amount.GetNormedValueAndFactor(textMatches[1], textMatches[2])
        const res = new Amount(va[0] * 1.0, va[1] ?? "", text)

        return res
    }

    /**
     * Returns an unified Representation string of an Value
     * @param {number} v Value of Amount
     * @param {string} a Type
     * @returns Representation
     */
    static Rep(v, a) {
        const va = Amount.GetOptimizedValue(v, a)

        return va[0] + va[1]
    }

    /**
     * Gets the Normalized Values for
     * @param {number} v Value
     * @param {string} a Type
     */
    static GetOptimizedValue(v, a) {
        if (a === "") {
            return [v, a]
        }
        const va = this.GetNormedValueAndFactor(v, a)
        let Value = va[0]
        let Amount = va[1]

        if (Value >= 1e3) {
            Value *= 1e-3
            Amount = "k" + Amount
        } else if (Value <= 1e-6) {
            Value *= 1e9
            Amount = "n" + Amount
        } else if (Value <= 1e-3) {
            Value *= 1e6
            Amount = "µ" + Amount
        } else if (Value < 1) {
            Value *= 1e3
            Amount = "m" + Amount
        }
        return [Value, Amount]
    }


    /**
     * Splits a String into parameters
     * @param {string[]} text Splitted String
     * @returns Splitstring Information
     */
    static SplitStringArray(text) {
        const res = []

        text.forEach(element => {
            if (typeof (element) === typeof ("")) {
                if (matchAmount.test(element) === false) {
                    res.push(element)
                } else {
                    const x = matchAmount.exec(element)
                    if (x.index > 0) {
                        res.push(element.substring(0, x.index - 1))
                    }
                    const a = Amount.Parse(x[0])
                    a.representation = x[0]
                    res.push(a)
                    const followelem = x.index + x[0].length
                    if (element.length > followelem) {
                        res.push(element.substring(followelem).trim())
                    }
                }
            } else {
                res.push(element)
            }
        });

        return res
    }
}

/**
 * Holds a dimension of 2 or 3
 * values should be in mm
 */
class Dimension {
    /**
     * Dimension
     * @param {number} x Width
     * @param {number} y Length
     * @param {number|undefined} z Height
     */
    constructor(x, y, z) {
        this.length = x
        this.width = y
        this.height = z
    }


    /**
     * Parses an dimension
     * @param {string} text Input String
     * @returns Dimension
     */
    static Parse(text) {
        if (matchDimension.test(text) === false) {
            return undefined
        }

        const textMatches = matchDimension.exec(text)
        const res = [textMatches[1] * 1.0, textMatches[2] * 1.0]

        if (textMatches[3] !== undefined) {
            res.push(textMatches[3] * 1.0)
        } else {
            res.push(undefined)
        }

        return new Dimension(res[0], res[1], res[2])
    }

    /**
     * String
     * @returns String
     */
    static Rep(dim) {
        if (dim === undefined) {
            return ("")
        }
        if ((dim.length ?? 0) === 0 && (dim.width ?? 0) === 0 && (dim.height ?? 0) === 0) {
            return ("")
        }
        if ((dim.height ?? 0) === 0 && (dim.length ?? 0) !== 0 && (dim.width ?? 0) !== 0) {
            return (`${dim.length}mm x ${dim.width}mm`)
        }
        return (`${dim.length}mm x ${dim.width}mm x ${dim.height}mm`)
    }

    /**
     * Splits a String into parameters
     * @param {string[]} text Splitted String
     * @returns Splitstring Information
     */
    static SplitStringArray(text) {
        const res = []

        text.forEach(element => {
            if (typeof (element) === typeof ("")) {
                if (matchDimension.test(element) === false) {
                    res.push(element)
                } else {
                    const x = matchDimension.exec(element)
                    if (x.index > 0) {
                        res.push(element.substring(0, x.index - 1))
                    }
                    res.push(Dimension.Parse(x[0]))
                    const followelem = x.index + x[0].length
                    if (element.length > followelem) {
                        res.push(element.substring(followelem).trim())
                    }
                }
            } else {
                res.push(element)
            }
        });

        return res
    }
}

/**
 * Date representation
 */
class StorageDate {
    /**
     * Erzeugt ein neues Datum
     * @param {number|undefined} d Tag
     * @param {number|undefined} m Monat
     * @param {number|undefined} y Jahr
     */
    constructor(d, m, y) {
        const now = new Date()
        const tmp = y * 1
        const fullyear = now.getFullYear()
        const filledFullYear = (Math.round(fullyear / 100) * 100) + tmp
        let a = y ?? fullyear
        a = a < 1000 ? filledFullYear : a
        const dd = d ?? now.getDay()
        const mm = m ?? now.getMonth()
        this.year = a * 1
        this.day = dd * 1
        this.month = mm * 1
    }

    /**
     * Date Representation
     * @returns Normalized String of the date
     */
    toString() {
        const date = new Date(this.year, this.month - 1, this.day)
        return date.toLocaleDateString("de-DE")
    }

    static Rep(d) {
        if (d === undefined) {
            return ""
        }
        return d.toString()
    }

    /**
     * Parse Date
     * @param {string} text Input
     * @returns Text
     */
    static Parse(text) {
        let dt = isDate1(text)
        if (dt === undefined) {
            dt = isDate2(text)
        }
        if (dt === undefined) {
            return undefined
        }
        return new StorageDate(dt[2], dt[1], dt[0])
    }

    /**
        * Splits a String into parameters
        * @param {string[]} text Splitted String
        * @returns Splitstring Information
        */
    static SplitStringArray(text) {
        const res = []

        text.forEach(element => {
            if (typeof (element) === typeof ("")) {
                testForDateOrAppend()
            } else {
                res.push(element)
            }

            function testForDateOrAppend() {
                if (matchDate1.test(element) === true) {
                    testElementAgainstRegexp(element, matchDate1)
                } else if (matchDate2.test(element) === true) {
                    testElementAgainstRegexp(element, matchDate2)
                } else {
                    res.push(element)
                }
            }
        });

        return res

        function testElementAgainstRegexp(element, rege) {
            const x = rege.exec(element)
            if (x.index > 0) {
                res.push(element.substring(0, x.index - 1))
            }
            res.push(StorageDate.Parse(x[0]))
            const followelem = x.index + x[0].length
            if (element.length > followelem) {
                res.push(element.substring(followelem).trim())
            }
        }
    }
}





function isDate1(text) {
    if (matchDate1.test(text) === false) {
        return undefined
    }
    const date1 = matchDate1.exec(text)
    let day = date1[1]
    let month = date1[2]
    let year = date1[3]
    return [year, month, day]
}

function isDate2(text) {
    if (matchDate2.test(text) === false) {
        return undefined
    }
    const date1 = matchDate2.exec(text)
    let year = date1[1]
    let month = date1[2]
    let day = date1[3]
    return [year, month, day]
}

export { Item, Amount, Dimension, StorageDate }