import { Amount, Dimension, Item, StorageDate } from "./classes"

/**
 * Parses text to a list of values
 * @param {string} text Text to parse
 * @returns splits string into a list of classes
 */
function ParseToList(text) {
    let input = [text]
    let length = input.length
    let lastlength = 0

    let max = 2
    // while ((lastlength != length) && (max > 0)) {
    max = max - 1
    lastlength = length
    input = Dimension.SplitStringArray(input)
    length = input.length
    // }


    lastlength = 0
    max = 2
    while ((lastlength !== length) && (max > 0)) {
        max = max - 1
        lastlength = length
        input = StorageDate.SplitStringArray(input)
        length = input.length
    }


    lastlength = 0
    max = 2
    while ((lastlength !== length) && (max > 0)) {
        max = max - 1
        lastlength = length
        input = Amount.SplitStringArray(input)
        length = input.length
    }


    return input
}

/**
 * Reads a string and creates an item
 * @param {string} text String to parse
 * @returns parsed item
 */
function Parse(text) {
    const l = ParseToList(text)
    const result = new Item()
    result.amount = new Amount()
    result.dimension = new Dimension()
    result.date1 = new StorageDate()
    result.date2 = undefined


    const amounts = l.filter(x => x.constructor.name === 'Amount')
    if (amounts.length > 0) {
        result.params.amount = amounts[0].value
        result.params['amount-type'] = amounts[0].type
        l.splice(l.indexOf(amounts[0]), 1)
    }
    const dimensions = l.filter(x => x.constructor.name === 'Dimension')
    if (dimensions.length > 0) {
        result.params.width = dimensions[0][0]
        result.params.length = dimensions[0][1]
        result.params.heigth = dimensions[0][2]
        l.splice(l.indexOf(dimensions[0]), 1)
    }

    const dates = l.filter(x => x.constructor.name === 'StorageDate')
    if (dates.length === 1) {
        result.params['best-before'] = dates[0]
        l.splice(l.indexOf(dates[0]), 1)
    }


    for (let index = 0; index < l.length; index++) {
        const element = l[index];
        if (element.constructor.name === 'Amount') {
            l[index] = element.representation
        }
    }

    result.params.name = l.join(" ")

    if (result.name === "") {
        return undefined
    }

    return result
}

function ParseNew(text) {
    const result={params:{}}



    return result;
}


export { ParseToList, Parse }