import { useEffect, useState } from "react";
import Btn from "../components/common.btn";
import SingleInputField from "../components/input.field.single";
import ItemCard from "../components/item.card";
import ItemList from "../components/item.list";
import IconSvg from "../components/icon/svgIcon";
import Api from "../connector/apiv3";
import { Parse } from "../helper/parser";


function PageInputSingle(props) {
    const [Item, setItem] = useState(undefined)
    const [Items, setItems] = useState([])
    const [FieldValue, setFieldValue] = useState("test1")

    const [, setDimX] = useState("")
    const [, setDimY] = useState("")
    const [, setDimZ] = useState("")

    const getdata = (x) => {
        setItem(Parse(x))
    }

    const additem = () => {
        setItems([...Items, Item])
        setFieldValue("")
        setItem({})
    }

    useEffect(() => {
        setDimX(Item?.params?.length ?? 0)
        setDimY(Item?.params?.width ?? 0)
        setDimZ(Item?.params?.height ?? 0)
    }, [Item])


    const addAll = () => {
        if (props.onClick !== undefined) {
            props.onClick(Items)
        } else {
            Items.forEach(item => {
                Api.SaveItem(item)
                    .then(e => console.log(`saved ${e}`))
                    .catch(e => console.log(`save failed ${e}`))
            });
        }
        setItems([])
    }

    return (
        <div >
            <IconSvg Item={Item} Width="100" Height="100" Radius="50" Border="10" />

            <SingleInputField onChange={e => getdata(e)} Content={FieldValue} />
            <ItemCard Item={Item} Editable={true} />

            <Btn Caption="Hinzufügen" onClick={additem} />
            <ItemList Items={Items} className="small" />

            <Btn Caption="Speichern" onClick={addAll} />
        </div>
    );
}

export default PageInputSingle;