import { useState } from "react";
import SingleInputField from "../components/input.field.single";
import ItemList from "../components/item.list";
import Api from "../connector/apiv3";

function PageSearch(props) {
    const testitems = []
    const [Items, setItems] = useState(testitems)

    const change = (e) => {
        if (e == "") {
            setItems([])
            return
        }
        Api.QueryStorageObjects(undefined, e, undefined, undefined, undefined).then(e => {
            setItems(e)
            console.log("setitems"+e)
        }).catch(e => {
            setItems([])
        })

    }

    const geturl = (x) => {
        return "/id/" + x
    }

    return (
        <div >
            <h1>Suche</h1>
            <SingleInputField onChange={change} />
            <ItemList Items={Items} GetUrl={geturl} />
        </div>
    );
}

export default PageSearch;