import { useState, useEffect } from "react";
import Btn from "../components/common.btn";
import Api from "../connector/apiv3";

function Login(params) {
    const [Username, setUsername] = useState("")
    const [Password, setPassword] = useState("")
    const [Status, setStatus] = useState(false)
    const nullFunc = (stat) => { return false }
    const SetStatus = params?.Result ?? nullFunc

    const CheckLoggedIn = () => {
        Api.LoggedIn().then(e => {
            SetStatus(e)
            setStatus(e)
        })
    }

    const Click = (e) => {
        Api.Login(Username, Password).then((v)=>{sessionStorage.setItem("token", v)}).catch((error) => {sessionStorage.setItem("token",undefined)}).finally(  CheckLoggedIn())
    }

    // useEffect(() => {
    //     CheckLoggedIn()
    // }, [Status])


    if (Status === false) {
        return (
            <div>
                <h2>Login</h2>
                <input type="text" value={Username} onChange={e => setUsername(e.target.value)} />
                <input type="password" value={Password} onChange={e => setPassword(e.target.value)} />
                <Btn Caption="Login" onClick={Click} />
            </div>
        )
    }
    return (
        <div>
            <h2>Login</h2>
            <Btn Caption="Logout" onClick={Click} />
        </div>
    )

}

export default Login;