import { useState } from 'react';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import Api from './connector/apiv3';
import PageInputSingle from './pages/input.single';
import Login from './pages/login';
import PageSearch from './pages/search.simple';
import SvgTestPage from './pages/svgtestpage';
import Btn from './components/common.btn';



function App() {
  const [LoggedIn, setLoggedIn] = useState(true)
  const router = createHashRouter([
    {
      path: "/",
      element: <Login Result={setLoggedIn} />,
      children: [
        {
          path: "search",
          element:  <PageSearch />,
        },
        {
          path: "icons",
          element:  <SvgTestPage />,
        },
        {
          path: "input",
          element:  <PageInputSingle />,
        },
      ]
    },
  ])


  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  )
}

export default App;
