import React from "react";
import "./svgIcon.css"



const TextWeight = (w) => {
    let Weight = w?.weight ?? 0
    if (Weight === 0) {
        return (<text></text>)
    }

    const Border = w?.Border ?? 5

    const X = ((w?.width ?? 0) / 2.0)
    const Y = (w?.height ?? 0) - Border - (w.height * 0.3)
    const FS = (w?.height ?? 0) * 0.13
    return (
        <text
            transform={`translate(${X},${Y})`}
            dominant-baseline="middle"
            text-anchor="middle"
            fontSize={FS}
        >{Weight}</text>
    )
}

export { TextWeight }

/**
 * Generates an icon-svg for weight-based items
 * @param {{Width:number Height:number Radius:number Border:number}} param 
 * @returns svg
 */
const WeightSvg = (param) => {
    const CanvasWidth = param?.Width ?? 200
    const CanvasHeight = param?.Height ?? 200
    const Radius = param?.Radius ?? 0
    const Border = param?.Border ?? 5
    const ScaleFactor = Math.min(CanvasWidth, CanvasHeight) - 2 * Border
    const className = param.className

    const WeightPath = "m -0.50607391,-0.83762246 c 0.0962385,-0.00269 0.12749543,0.0305166 0.12585033,0.0661331 -0.001777,0.0356164 -0.0400583,0.0390712 -0.0469677,0.0498466 -0.001662,0.002575 -0.006967,0.009788 -0.008226,0.0175203 -0.003998,0.0247588 -0.005026,0.0572496 -0.002106,0.059964 0.00292,0.002714 0.13243072,0.008472 0.13818857,0.015793 0.002805,0.003684 0.002755,0.12914051 0.002854,0.24429764 9.788e-5,0.1168023 -0.001447,0.23195943 -0.001835,0.23195943 -3.7911e-4,2.5198e-4 -0.15052683,0.007551 -0.22126623,0.006448 -0.0710684,-0.0011 -0.20646028,-0.0103643 -0.20892799,-0.0126673 -8.5684e-4,-0.001031 -0.0021,-0.16780038 -0.00173,-0.21962114 8.9884e-4,-0.12420518 -0.003091,-0.24841036 0.004839,-0.25745841 0.007728,-0.00839 0.1455915,-0.009542 0.14805912,-0.0123383 0.00247,-0.002797 0.003755,-0.059964 -8.7364e-4,-0.0739473 -0.00462,-0.0139829 -0.0486124,-0.0188359 -0.0496818,-0.0526428 -0.00105,-0.0338891 0.0246762,-0.0610333 0.120915,-0.0637477 z"

    const Weight = param.Weight ?? 0

    return (
        <svg className={className} width={CanvasWidth} height={CanvasHeight} xmlns="http://www.w3.org/2000/svg">
            <rect
                x={2}
                y={2}
                rx={Radius}
                ry={Radius}
                width={CanvasWidth - 4}
                height={CanvasHeight - 4}
                className="frame"
            />
            <path
                className="weight"
                d={WeightPath}
                vectorEffect="non-scaling-stroke"
                transform={` translate(${CanvasWidth / 2 + ScaleFactor / 2},${CanvasHeight / 2 + ScaleFactor / 2}) scale(${ScaleFactor}) `}
            />
            <TextWeight weight={Weight} width={CanvasWidth} height={CanvasHeight} Border={Border} />

        </svg>
    )


}



export default WeightSvg