import { useEffect, useState } from "react";
import Api from "../connector/apiv3";
import { Amount as Amt, Dimension as Dim, StorageDate } from "../helper/classes";
import IconSvg from "./icon/svgIcon";
import './item.card.css'

/**
 *
 * @param {{Editable:bool,Item:Item,Small:bool,WithCard:bool,Class:string}} props
 * @returns
 */
function ItemCard(props) {
    const [Id, setId] = useState(undefined)
    const [Name, setName] = useState("")
    const [Amount, setAmount] = useState("")
    const [AmountType, setAmountType] = useState("")
    const [AmountValue, setAmountValue] = useState("")
    const [BestBefore, setBestBefore] = useState("")
    const [Date, setDate] = useState("")
    const [Dimension,] = useState(new Dim())
    const [DimX, setDimX] = useState("")
    const [DimY, setDimY] = useState("")
    const [DimZ, setDimZ] = useState("")
    const [Class] = useState(props.Class)
    const [, setLocation] = useState(undefined)
    const [Editable, setEditable] = useState(false)

    const EditableProperty = props?.Editable
    const ItemProperty = props?.Item
    const ItemSmall = props?.Small
    const WithCard = props?.Card ?? false

    useEffect(() => {
        if (EditableProperty !== undefined) {
            setEditable(EditableProperty)
            return () => { }
        }
    }, [EditableProperty])

    useEffect(() => {
    }, [Dimension])


    useEffect(() => {
        setName(ItemProperty?.params?.name ?? "")
        setBestBefore(ItemProperty?.params['best-before'] ?? "")
        setDate(ItemProperty?.params?.bought ?? "")
        setId(ItemProperty?.uuid ?? "")
        setAmount("")

        setDimX(ItemProperty?.params?.length ?? "")
        setDimY(ItemProperty?.params?.width ?? "")
        setDimZ(ItemProperty?.params?.height ?? "")

        const parsed = Amt.Parse(ItemProperty?.amount?.representation)
        if (parsed !== undefined) {
            setAmount(parsed.representation)
            const av = Amt.GetOptimizedValue(ItemProperty?.params?.amount, ItemProperty?.params['amount-type'] || "")
            setAmountType(av[1])
            setAmountValue(av[0])
        }

        if (ItemProperty?.params?.location !== undefined) {

            setLocation(ItemProperty?.params?.location)
        } else {
            setLocation(undefined)
        }
        return () => {

        }
    }, [ItemProperty])

    const renderDim = (dim) => {
        if (dim === undefined) {
            return (<div className="card-text">&nbsp;</div>)
        }
        if ((dim.Length ?? 0) === 0 && (dim.Width ?? 0) === 0 && (dim.Height ?? 0) === 0) {
            return (<div className="card-text">&nbsp;</div>)
        }
        if ((dim.Height ?? 0) === 0 && (dim.Length ?? 0) !== 0 && (dim.Width ?? 0) !== 0) {
            return (<div className="card-text">{dim.Length}mm x {dim.Width}mm</div>)
        }
        return (<div className="card-text">{dim.Length}mm x {dim.Width}mm x {dim.Height}mm</div>)
    }

    const renderDate = (BestBefore) => {
        if (BestBefore === undefined) {
            return (<div className="card-text"></div>)
        }

        const date = BestBefore.toString()
        return (
            <div className="card-text">MHD: {date}</div>
        )
    }

    const geturl = (x) => {
        return "#" + x
    }

    const renderLink = () => {
        if (Id === undefined) {
            return (<span> {Name} </span>)
        }
        if (props.GetUrl instanceof Function) {
            return (
                <a href={props.GetUrl(Id)}>{Name}</a>
            )
        }

        return (
            <a href={geturl(Id)}>{Name}</a>
        )
    }

    const renderPath = () => {

    }



    const renderEditable = () => {
        return (
            <div className={"container card " + Class}>
                <div className="row">
                    <div className="col-12">
                        <input type="text" className="form-control" id="name" placeholder="Name" value={Name} onChange={e => setName(e.target.value)} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <input type="text" className="form-control" placeholder={AmountValue} value={AmountValue} onChange={e => setAmountValue(e.target.value)} />
                    </div>
                    <div className="col-6">
                        <input type="text" className="form-control" placeholder="Stück" value={AmountType} onChange={e => setAmountType(e.target.value)} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <input type="text" className="form-control" placeholder="Angeschafft" value={Date} onChange={e => setDate(e.target.value)} />
                    </div>
                    <div className="col-6">
                        <input type="text" className="form-control" placeholder="MHD" value={BestBefore} onChange={e => setBestBefore(e.target.value)} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <input type="text" className="form-control" placeholder="" value={DimX} onChange={e => { setDimX(e.target.value) }} />
                    </div>
                    <div className="col-4">
                        <input type="text" className="form-control" placeholder="" value={DimY} onChange={e => { setDimY(e.target.value) }} />
                    </div>
                    <div className="col-4">
                        <input type="text" className="form-control" placeholder="" value={DimZ} onChange={e => { setDimZ(e.target.value) }} />
                    </div>
                </div>
            </div>
        )
    }


    const render = () => {
        if (Editable) {
            return renderEditable()
        }

        if (ItemSmall) {
            return (
                <div className="row">
                    <div className="col-1 col-xs-5 text-end">
                        <IconSvg Item={ItemProperty} Height={30} Width={30} Radius={15} Border={15} />
                    </div>
                    <div className="col-1 col-xs-5 text-end">
                        {Amount}
                    </div>
                    <div className="col-6 col-xs-7 text-start" >{Name}</div>
                    <div className="col-2 col-xs-6 small">{StorageDate.Rep(BestBefore)}</div>
                    <div className="col-2 col-xs-6 small">{Dim.Rep(Dimension)}</div>
                </div>
            )
        }
        return (
            <div className={"card " + Class}>
                <h5>
                    <span className="mr-3">
                        <em>
                            {Amount}&nbsp;
                        </em>
                    </span>
                    {renderLink()}
                </h5>
                {renderDate(BestBefore)}
                {renderDim(Dimension)}
                {renderPath()}
            </div>
        )

    }

    return (
        render()
    )
}


export default ItemCard;