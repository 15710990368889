import { Item } from "../helper/classes"

class Api {

    static GetItemLink(x) {
        return "/item/" + x
    }

    static GetApiBaseUrl() {
        return "https://srv419299.hstgr.cloud:4711/"
        // return "http://192.168.178.54:3340/v1"
    }

    /**
     * Query the backend to parse the item
     * @param {string} x String to be parsed
     * @returns Promise
     */
    static ParseSingleItem(x) {
        const s = this.GetApiBaseUrl() + "/parser/"
        const data = {
            method: "POST",
            body: x,
        }
        return new Promise(function (resolve, abort) {
            fetch(s, data).then((response) => response.json()).then(json => {
                if (json.length > 0) {
                    if (json[0] !== undefined) {
                        resolve(json[0])
                    }
                }
                resolve(undefined)
            }).catch(e => abort(e))
        })
    }

    /**
     * Check for active login
     * @returns bool Logged in Status
     */
    static LoggedIn() {

        return new Promise(function (resolve, abort) {
            const b = sessionStorage.getItem("token");
            if (b !== undefined) {
                resolve(true);
            } else {
                resolve(false);
            }
        });
    }

    /**
     * Send user login to backend and gets the auth cookie
     * @param {string} user Username
     * @param {string} password Password
     * @returns Promise
     */
    static Login(user, password) {
        const url = this.GetApiBaseUrl()

        return new Promise((resolve, reject) => {

            fetch(url, {
                method: "POST",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    type: "login",
                    user: user,
                    passwd: password
                }),

            }).then(response => response.json()).then(json => resolve(json['new-token'])).catch((reason) => reject(reason))
        })
    }

    /**
     * Query storage items
     * @param {int|undefined} id Ident of storage item
     * @param {string|undefined} name String that is contained in storage item
     * @param {int|undefined} locationid Ident of the location
     * @param {string|undefined} date2after Earliest date
     * @param {string|undefined} date2before Latest date
     * @returns list of storage items
     */
    static QueryStorageObjects(id, name, locationid, date2after, date2before) {
        const url = this.GetApiBaseUrl()

        return new Promise((resolve, reject) => {

            let body = {
                type: "query",
                token: sessionStorage.getItem("token"),
            };
            if (id !== undefined) {
                body.entities = [id]
            };
            if (name !== undefined) {
                body['search-string'] = name
            };


            fetch(url, {
                method: "POST",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),

            }).then(response => response.json()).then(json => resolve(json['entities'])).catch((reason) => reject(reason))
        })
    }


    /**
     * Get a Storage Path
     * @param {int} id Ident of the location
     */
    static GetStoragePath(id) {
        return new Promise(function (resolve, reject) {

            const url = this.GetApiBaseUrl() + "/storagePath/?id=" + encodeURIComponent(id)
            const XHR = new XMLHttpRequest();
            XHR.withCredentials = true

            XHR.addEventListener('load',
                (event) => {
                    if (event.target.response == undefined) {
                        reject();
                        return;
                    }
                    resolve(JSON.parse(event.target.response))
                }
            );

            XHR.addEventListener('error',
                (event) => {
                    reject()
                }
            );

            XHR.open('GET', url);

            XHR.send()
        })
    }

    /**
     * Save Item to Database
     * @param {object} item StorageItem
     * @returns Storage Item with Id
     */
    static SaveItem(item) {
        const url = this.GetApiBaseUrl() + "/storageObject/"

        return new Promise(function (resolve, abort) {
            const XHR = new XMLHttpRequest();

            const urlEncodedDataPairs = []
            XHR.withCredentials = true

            XHR.addEventListener('load',
                (event) => {
                    if (event.target.status != 201) {
                        abort()
                        return
                    }
                    resolve(JSON.parse(event.target.body))
                }
            );

            XHR.addEventListener('error',
                (event) => {
                    abort()
                }
            );

            XHR.open('POST', url);

            XHR.setRequestHeader('Content-Type', 'application/json');
            XHR.send(JSON.stringify(item));
        })
    }

}


export default Api;