import { useState } from "react";

/**
 * Common Button
 * @param {{Caption:string,onClick:React.MouseEventHandler}} props 
 * @returns Button
 */
function Btn(props) {
    const [Caption, setCaption] = useState(props.Caption ? props.Caption : "OK")

    return (<button onClick={e => props.onClick(e)}>{Caption}</button>);
}

export default Btn;