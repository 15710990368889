import React from "react";
import { Camera, V3d } from "../../helper/3dmath";
import "./svgIcon.css"

/**
 * Displays a svg with 3d-box model
 * @param {{x:number y:number z:number Width:number Height:number Radius:number BorderOffset:number}} param 
 * @returns svg
 */
const BoxSvg = (param) => {

    const Width = param.x ?? 10
    const Length = param.y ?? 10
    const Height = param.z ?? 40

    const CanvasWidth = param.Width ?? 200
    const CanvasHeigth = param.Height ?? 200

    const Radius = param.Radius ?? 0
    const BorderOffset = param.Border ?? 10

    const ctLow3D = new V3d(Width, Length, 0)
    const leftLow3D = new V3d(Width, 0, 0)
    const rightLow3D = new V3d(0, Length, 0)
    const ctHigh3D = new V3d(Width, Length, Height)
    const leftHigh3D = new V3d(Width, 0, Height)
    const rightHigh3D = new V3d(0, Length, Height)
    const ctBackHigh3D = new V3d(0, 0, Height)
    const ctBackLow3D = new V3d(0, 0, 0)
    
    const className=param.className

    const ct3D = new V3d(Width / 2, Length / 2, Height / 2)

    const getCamPosition = () => {
        const maxlen = maxDimension()

        // Kleine Gegenstände
        // Objekt steht auf Tisch
        if (maxlen < 330) {
            return new V3d(600, 500, 400);
        }

        // Großes Objekt
        return new V3d(maxlen * 2, maxlen * 2.1, 1850)
    }

    const maxDimension = () => {
        return Math.max(Width, Height, Length);
    }


    const campt = getCamPosition()

    const Cam = new Camera(ct3D, campt, [CanvasWidth, CanvasHeigth])


    const midW = CanvasWidth / 2
    const midH = CanvasHeigth / 2
    Cam.OffsX = midW
    Cam.OffsY = midH

    Cam.AutoFocus([ctLow3D, ctHigh3D, ctBackHigh3D, leftHigh3D, rightHigh3D], BorderOffset)


    const ctLow = Cam.Project(ctLow3D)
    const ctHigh = Cam.Project(ctHigh3D)

    const rightHigh = Cam.Project(rightHigh3D)
    const rightLow = Cam.Project(rightLow3D)

    const leftHigh = Cam.Project(leftHigh3D)
    const leftLow = Cam.Project(leftLow3D)

    const ctBack = Cam.Project(ctBackHigh3D)
    const ctBackLow = Cam.Project(ctBackLow3D)

    if (ctLow === undefined) return (<div>ctlow is null</div>)
    if (ctHigh === undefined) return (<div>ctHigh is null</div>)
    if (rightHigh === undefined) return (<div>rightHigh is null</div>)
    if (rightLow === undefined) return (<div>rightLow is null</div>)
    if (leftHigh === undefined) return (<div>leftHigh is null</div>)
    if (leftLow === undefined) return (<div>leftLow is null</div>)
    if (ctBack === undefined) return (<div>ctBack is null</div>)

    return (
        <svg className={className} width={CanvasWidth} height={CanvasHeigth} xmlns="http://www.w3.org/2000/svg">
            <rect
                x={2}
                y={2}
                rx={Radius}
                ry={Radius}
                width={CanvasWidth - 4}
                height={CanvasHeigth - 4}
                className="frame"
            />

            <line
                x1={leftLow[0]}
                y1={leftLow[1]}
                x2={ctBackLow[0]}
                y2={ctBackLow[1]}
                className="hiddenLine"
            />
            <line
                x1={ctBack[0]}
                y1={ctBack[1]}
                x2={ctBackLow[0]}
                y2={ctBackLow[1]}
                className="hiddenLine"
            />
            <line
                x1={rightLow[0]}
                y1={rightLow[1]}
                x2={ctBackLow[0]}
                y2={ctBackLow[1]}
                className="hiddenLine"
            />

            <polygon
                points={[ctHigh, rightHigh, ctBack, leftHigh, ctHigh]}
                className="leftFace"
            />

            <polygon
                points={[ctLow, ctHigh, rightHigh, rightLow, ctLow]}
                className="rightFace"
            />

            <polygon
                points={[ctLow, ctHigh, leftHigh, leftLow, ctLow]}
                className="topFace"
            />

        </svg>
    )


}

export default BoxSvg