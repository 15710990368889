import React from "react";
import "./svgIcon.css"

/**
 * Generates an svg with dots for counts between 1 and 9 and a big dot for
 * bigger values
 * @param {{Count:number Width:number Height:number Radius:number}} param 
 * @returns 
 */
const CountSvg = (param) => {

    const Count = param.Count ?? 0
    const CanvasWidth = param.Width ?? 200
    const CanvasHeight = param.Height ?? 200
    const Radius = param.Radius ?? 0

    const className=param.className


    let rad = Math.min(CanvasHeight, CanvasWidth) / 10
    if (Count > 7) {
        rad = Math.min(CanvasHeight, CanvasWidth) / 12
    }
    const offset = rad * 2
    const offset30 = offset * 0.5
    const offset45 = offset * 0.707
    const offset60 = offset * 0.866

    let points = []
    let counter = 0

    const randomface = () => {
        counter = counter + 1
        if (counter == 1) {
            return "topFace"
        }
        if (counter == 2) {
            return "rightFace"
        }
        counter = 0
        return "leftFace"
    }

    switch (Count) {
        case 1:
            points = [
                [CanvasWidth / 2, CanvasHeight / 2, rad, randomface()]
            ]
            break;
        case 2:
            points = [
                [CanvasWidth / 2 - offset / 2, CanvasHeight / 2, rad, randomface()],
                [CanvasWidth / 2 + offset / 2, CanvasHeight / 2, rad, randomface()]
            ]
            break
        case 3:
            points = [
                [CanvasWidth / 2, CanvasHeight / 2 - offset60 / 2, rad, randomface()],
                [CanvasWidth / 2 - offset30, CanvasHeight / 2 + offset60 / 2, rad, randomface()],
                [CanvasWidth / 2 + offset30, CanvasHeight / 2 + offset60 / 2, rad, randomface()]
            ]
            break
        case 4:
            points = [
                [CanvasWidth / 2, CanvasHeight / 2 - offset45, rad, randomface()],
                [CanvasWidth / 2 - offset45, CanvasHeight / 2, rad, randomface()],
                [CanvasWidth / 2 + offset45, CanvasHeight / 2, rad, randomface()],
                [CanvasWidth / 2, CanvasHeight / 2 + offset45, rad, randomface()]
            ]
            break
        case 5:
            points = [
                [CanvasWidth / 2, CanvasHeight / 2, rad, randomface()],
                [CanvasWidth / 2 - offset45, CanvasHeight / 2 - offset45, rad, randomface()],
                [CanvasWidth / 2 - offset45, CanvasHeight / 2 + offset45, rad, randomface()],
                [CanvasWidth / 2 + offset45, CanvasHeight / 2 - offset45, rad, randomface()],
                [CanvasWidth / 2 + offset45, CanvasHeight / 2 + offset45, rad, randomface()]
            ]
            break
        case 6:
            points = [
                [CanvasWidth / 2, CanvasHeight / 2 - offset60, rad, randomface()],
                [CanvasWidth / 2 - offset30, CanvasHeight / 2, rad, randomface()],
                [CanvasWidth / 2 + offset30, CanvasHeight / 2, rad, randomface()],
                [CanvasWidth / 2, CanvasHeight / 2 + offset60, rad, randomface()],
                [CanvasWidth / 2 + offset30 * 2, CanvasHeight / 2 + offset60, rad, randomface()],
                [CanvasWidth / 2 - offset30 * 2, CanvasHeight / 2 + offset60, rad, randomface()],
            ]
            break
        case 7:
            points = [
                [CanvasWidth / 2, CanvasHeight / 2, rad, randomface()],
                [CanvasWidth / 2 - offset, CanvasHeight / 2, rad, randomface()],
                [CanvasWidth / 2 + offset30, CanvasHeight / 2 - offset60, rad, randomface()],
                [CanvasWidth / 2 + offset, CanvasHeight / 2, rad, randomface()],
                [CanvasWidth / 2 + offset30, CanvasHeight / 2 + offset60, rad, randomface()],
                [CanvasWidth / 2 - offset30, CanvasHeight / 2 + offset60, rad, randomface()],
                [CanvasWidth / 2 - offset30, CanvasHeight / 2 - offset60, rad, randomface()]
            ]
            break
        case 8:
            points = [

                [CanvasWidth / 2, CanvasHeight / 2 - offset / 2 - offset60, rad, randomface()],
                [CanvasWidth / 2, CanvasHeight / 2 + offset / 2 + offset60, rad, randomface()],
                [CanvasWidth / 2 + offset / 2 + offset60, CanvasHeight / 2, rad, randomface()],
                [CanvasWidth / 2 - offset / 2 - offset60, CanvasHeight / 2, rad, randomface()],
                [CanvasWidth / 2 + offset / 2, CanvasHeight / 2 - offset / 2, rad, randomface()],
                [CanvasWidth / 2 - offset / 2, CanvasHeight / 2 - offset / 2, rad, randomface()],
                [CanvasWidth / 2 + offset / 2, CanvasHeight / 2 + offset / 2, rad, randomface()],
                [CanvasWidth / 2 - offset / 2, CanvasHeight / 2 + offset / 2, rad, randomface()]
            ]
            break
        case 9:
            points = [

                [CanvasWidth / 2, CanvasHeight / 2, rad],
                [CanvasWidth / 2 + offset45, CanvasHeight / 2 + offset45, rad, randomface()],
                [CanvasWidth / 2 - offset45, CanvasHeight / 2 + offset45, rad, randomface()],
                [CanvasWidth / 2 + offset45, CanvasHeight / 2 - offset45, rad, randomface()],
                [CanvasWidth / 2 - offset45, CanvasHeight / 2 - offset45, rad, randomface()],
                [CanvasWidth / 2, CanvasHeight / 2 - 2 * offset45, rad, randomface()],
                [CanvasWidth / 2, CanvasHeight / 2 + 2 * offset45, rad, randomface()],
                [CanvasWidth / 2 - 2 * offset45, CanvasHeight / 2, rad, randomface()],
                [CanvasWidth / 2 + 2 * offset45, CanvasHeight / 2, rad, randomface()],
            ]
            break
        default:
            points = [
                [CanvasWidth / 2, CanvasHeight / 2, rad * 4, randomface()]
            ]
            break;
    }
    console.log(className)

    return (
        <svg className={className} width={CanvasWidth} height={CanvasHeight} xmlns="http://www.w3.org/2000/svg">
            <rect
                x={2}
                y={2}
                rx={Radius}
                ry={Radius}
                width={CanvasWidth - 4}
                height={CanvasHeight - 4}
                className="frame"
            />
            {points?.map(([x, y, r, c],k) => (
                <circle
                    className={c ?? "topFace"}
                    cx={x}
                    cy={y}
                    r={r}
                    key={k}

                />
            ))}

        </svg>
    )


}

export default CountSvg