import React from "react";
import BoxSvg from "./svgBox";
import CountSvg from "./svgCount";
import VolumeSvg from "./svgVolume";
import WeightSvg from "./svgWeight";


/**
 * Auto generates svg icon for the given item
 * @param {{Width:number Height:number Radius:number BorderAbs:number Border:number Item:object}} param
 * @returns svg
 */
const IconSvg = (param) => {

    const CanvasWidth = param.Width ?? 200
    const CanvasHeigth = param.Height ?? 200

    const Radius = param.Radius ?? 0
    const BorderOffset = param.BorderAbs
    const BorderPercent = param.Border ?? 10
    const className = param.className

    let Border = BorderPercent / 100 * Math.min(CanvasHeigth, CanvasWidth)
    if (BorderOffset !== undefined) {
        Border = BorderOffset
    }

    // console.log(`Border=${Border} BorderPercent=${BorderPercent} BorderOffset=${BorderOffset}`)
    const Item = param.Item ?? undefined

    if (Item?.param?.height ?? "" !== "") {
        return (
            <BoxSvg
                x={Item?.params?.width}
                y={Item?.params?.length}
                z={Item?.params?.height}
                Width={CanvasWidth}
                Height={CanvasHeigth}
                Radius={Radius}
                Border={Border}
                className={className}
            />
        )
    }


    if ((Item?.params['amount-type'] || "") === "g") {
        return (
            <WeightSvg
                Width={CanvasWidth}
                Height={CanvasHeigth}
                Weight={Item?.params?.amount}
                Radius={Radius}
                Border={Border}
                className={className}
            />
        )
    }
    if ((Item?.params['amount-type'] || "") === "l") {
        return (
            <VolumeSvg
                Volume={Item?.params?.amount}
                Width={CanvasWidth}
                Height={CanvasHeigth}
                Radius={Radius}
                Border={Border}
                className={className}
            />
        )
    }

    return (
        <CountSvg
            Count={Item?.params?.amount ?? 1}
            Width={CanvasWidth}
            Height={CanvasHeigth}
            Radius={Radius}
            Border={Border}
            className={className}
        />
    )
}

export default IconSvg